import React from 'react';
import {
  CompanyPageContainer,
  CompanyPageText,
  CompanyPageH2,
  CompanyPageH1,
  CompanyPageDescription,
  CompanyPageContentBlock,
} from './styles/styled-components';

export const About = () => (
  <CompanyPageContainer>
    <CompanyPageContentBlock>
      <CompanyPageH1>About Uploadcare</CompanyPageH1>
      <CompanyPageDescription>
        Uploadcare is file management platform and a&nbsp;CDN for user-generated content. It is
        a&nbsp;robust file API for uploading, managing, processing, rendering, optimizing and
        delivering users’ content.
      </CompanyPageDescription>
    </CompanyPageContentBlock>

    <CompanyPageContentBlock>
      <CompanyPageH2>What Uploadcare Does</CompanyPageH2>
      <CompanyPageText>
        We provide an infrastructure that includes:
        <ul>
          <li>Smart Storage powered with AI (object recognition, malicious content detection)</li>
          <li>File Uploader and Image Editor widgets</li>
          <li>
            URL API for image optimization and transformations (document and video conversion)
          </li>
          <li>Content Delivery Network</li>
        </ul>
      </CompanyPageText>
    </CompanyPageContentBlock>
  </CompanyPageContainer>
);
