import styled, { css } from 'styled-components';
import Image from '@uc-common/image';
import { Link } from '../../../Link';

export const FeaturesScreenContainer = styled.section(
  ({ theme: { tablet, laptop, desktop } }) => css`
    padding: 70px 0 100px;
    background-color: #000000;

    ${tablet()} {
      padding: 100px 0 80px;
    }

    ${laptop()} {
      padding: 100px 0 120px;
    }

    ${desktop()} {
      padding: 100px 0 140px;
    }
  `
);

export const FeaturesScreenCardsWrapper = styled.ul(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 40px;
    margin-top: 40px;
    width: 100%;

    ${tablet()} {
      margin-top: 70px;
    }

    ${laptop()} {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
    }
  `
);

export const FeaturesScreenDescription = styled.span(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 18px;
    line-height: 1.6;
    text-align: left;
    color: #bbc4d2;

    ${tablet()} {
      font-size: 20px;
    }

    ${laptop()} {
      font-size: 22px;
    }
  `
);

export const CardList = styled.ul(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 40px;
    align-items: flex-start;

    ${tablet()} {
      grid-template-columns: repeat(3, auto);
    }

    ${laptop()} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  `
);

export const CardListItem = styled(Link)(
  ({ theme: { desktop } }) => css`
    list-style-type: none;
    line-height: 24px;
    text-align: left;
    color: #596570;
    text-decoration: none;

    ${desktop()} {
      font-size: 16px;
    }

    &:hover {
      text-decoration: underline;
    }
  `
);

export const CardIcon = styled(Image)(
  ({ theme: { tablet } }) => css`
    width: 58px;
    height: 58px;
    position: relative;
    bottom: 10px;
    margin-bottom: 25px;

    ${tablet()} {
      margin-bottom: 23px;
    }
  `
);

export const CardHeading = styled.span(
  ({ theme: { desktop } }) => css`
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
    text-align: left;
    color: #000000;
    letter-spacing: -0.025em;
    margin-bottom: 20px;
    display: block;

    ${desktop()} {
      font-size: 25px;
    }
  `
);

export const FeaturesScreenCard = styled.div(
  ({ theme: { laptop, desktop }, $isDark = true }) => css`
    background-color: #f6f7f8;
    border-radius: 12px;
    border: 1px solid #f1f2f3;
    padding: 0px 30px 45px 30px;

    ${$isDark &&
    css`
      background-color: #0f1214;
      border-color: #1c2528;

      ${CardHeading} {
        color: #ffffff;
      }

      ${CardListItem} {
        color: #bbc4d2;
      }
    `}

    ${laptop()} {
      padding: 0px 20px 40px 20px;
    }

    ${desktop()} {
      padding: 0px 30px 40px 30px;
    }
  `
);
