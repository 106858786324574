import React from 'react';

import { Link } from '~/src/components/Link';
import {
  CompanyPageContainer,
  CompanyPageH2,
  CompanyPageContentBlock,
} from './styles/styled-components';
import {
  FeaturesScreenCardsWrapper,
  FeaturesScreenCard,
  CardList,
  CardListItem,
  CardIcon,
  CardHeading,
} from '../forFeatures/FeaturesScreen/styles/FeaturesScreen.styles';
import { PRODUCTS_DATA } from './data';

export const Products = () => (
  <CompanyPageContainer>
    <CompanyPageContentBlock>
      <CompanyPageH2>Uploadcare SaaS Products/Services</CompanyPageH2>

      <FeaturesScreenCardsWrapper>
        {PRODUCTS_DATA.map(({ header, headerIcon, links = [] }) => (
          <FeaturesScreenCard $isDark={false} key={header}>
            <CardIcon uuid={headerIcon} />
            <CardHeading>{header}</CardHeading>
            <CardList>
              {links.map((link) => (
                <CardListItem
                  key={link.title}
                  as={Link}
                  to={link.url}
                  data-analytics={link.dataAnalytics}
                >
                  {link.title}
                </CardListItem>
              ))}
            </CardList>
          </FeaturesScreenCard>
        ))}
      </FeaturesScreenCardsWrapper>
    </CompanyPageContentBlock>
  </CompanyPageContainer>
);
