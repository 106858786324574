import { Container } from '@uc-common/container';
import styled, { css } from 'styled-components';

export const CountriesContainer = styled(Container)(
  ({ theme: { tablet } }) => css`
    display: grid;
    justify-content: space-between;
    margin-bottom: 80px;
    gap: 8px;
    grid-template-columns: 1fr;

    ${tablet()} {
      margin-bottom: 120px;
      grid-template-columns: repeat(3, 1fr);
    }
  `
);
