import { Container } from '@uc-common/container';
import styled, { css } from 'styled-components';

export const JobsContainer = styled(Container)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    margin-top: 40px;
    margin-bottom: 120px;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${tablet()} {
      margin-top: 60px;
    }

    ${laptop()} {
      margin-top: 80px;
    }

    ${desktop()} {
      margin-top: 120px;
    }
  `
);

export const JobsHeading = styled.h2(
  ({ theme: { tablet, desktop } }) => css`
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.025em;
    color: black;
    font-weight: 700;
    margin-bottom: 30px;

    ${tablet()} {
      font-size: 28px;
    }

    ${desktop()} {
      font-size: 32px;
    }
  `
);

export const JobsText = styled.p(
  ({ theme: { tablet, desktop } }) => css`
    font-size: 18px;
    letter-spacing: -0.015em;
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.4em;
    color: #596570;

    ${tablet()} {
      font-size: 20px;
    }

    ${desktop()} {
      font-size: 22px;
    }

    strong {
      font-weight: 500;
    }
  `
);
