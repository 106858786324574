import styled, { css } from 'styled-components';

export const CountryContainer = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    display: grid;
    justify-items: center;
    gap: 8px;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 70px;
    padding: 25px 10px 10px 10px;

    ${tablet()} {
      grid-template-rows: min-content 93px 40px;
      padding: 28px 20px 35px 20px;
    }

    ${laptop()} {
      grid-template-rows: min-content 80px 50px;
    }

    ${desktop()} {
      padding: 28px 40px 35px 40px;
    }
  `
);

export const CountryName = styled.h2`
  font-size: 16px;
  line-height: 1.6em;
  text-align: center;
  font-weight: 700;
`;

export const CountryText = styled.p(
  ({ theme: { tablet } }) => css`
    font-size: 16px;
    line-height: 1.6em;
    text-align: center;
    color: #596570;
    margin-bottom: 10px;

    ${tablet()} {
      margin-bottom: 0;
    }
  `
);

export const CountryBall = styled.div(
  ({ theme: { laptop }, icon }) => css`
    width: 30px;
    height: 30px;
    background: center / contain no-repeat url(${icon});

    ${laptop()} {
      width: 40px;
      height: 40px;
    }
  `
);
