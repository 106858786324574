import React from 'react';
import styled, { css } from 'styled-components';
import { CompanyPageContainer, CompanyPageH2, CompanyPageText } from './styles/styled-components';

const HistorySectionContentBlock = styled.div(
  ({ theme: { laptop } }) => css`
    margin-left: auto;
    margin-right: auto;
    padding: 25px 15px;
    background-color: rgb(246, 247, 248);
    border-radius: 12px;
    border: 1px solid rgb(241, 242, 243);

    ${laptop()} {
      padding: 25px 45px 30px;
    }
  `
);

export const HistoryHeading = styled(CompanyPageH2)(
  ({ theme: { tablet, desktop } }) => css`
    font-size: 18px;
    text-align: left;
    width: 100%;

    ${tablet()} {
      font-size: 20px;
    }

    ${desktop()} {
      font-size: 25px;
    }
  `
);

export const History = () => (
  <CompanyPageContainer>
    <HistorySectionContentBlock>
      <HistoryHeading>Uploadcare History</HistoryHeading>

      <CompanyPageText>
        <ul>
          <li> Founded Date Oct 1, 2011</li>
          <li> Located in Vancouver, British Columbia, Canada.</li>
          <li> Last funding round closed on Nov 12, 2019 from a Seed round.</li>
        </ul>
      </CompanyPageText>
    </HistorySectionContentBlock>
  </CompanyPageContainer>
);
