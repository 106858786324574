import React from 'react';
import { ButtonTypeEnum } from '@uc-common/button';

import {
  ClientsCasesContainer,
  ClientsCasesWrapper,
  ClientsCasesHeading,
  ClientsCasesButton,
  ClientsCasesPreviews,
  ClientsCasesPreview,
  ClientsCasesPreviewLogo,
  ClientsCasesPreviewParagraph,
  ClientsCasesPreviewAuthor,
  ClientsCasesPreviewAuthorInfo,
  ClientsCasesPreviewPhoto,
} from './styles/ClientsCases.styles';

import { Link } from '../Link';
import { CASES } from './data';

export const ClientsCases = ({ className, ppc = false, heading = 'Loved by developers 💛' }) => (
  <ClientsCasesContainer className={className}>
    <ClientsCasesWrapper>
      {heading && <ClientsCasesHeading as="h2">{heading}</ClientsCasesHeading>}
      {!ppc && (
        <ClientsCasesButton forwardedAs={Link} to="/customers/" type={ButtonTypeEnum.DARK}>
          Read customer stories
        </ClientsCasesButton>
      )}

      <ClientsCasesPreviews>
        {CASES.map((item) => (
          <ClientsCasesPreview as={ppc && 'div'} key={item.companyName} to={!ppc && item.href}>
            <ClientsCasesPreviewLogo
              data-blink-src={item.logo.id}
              data-blink-quality="smart_retina"
              $width={item.logo.width}
              alt={item.companyName}
            />
            <ClientsCasesPreviewParagraph>{item.paragraph}</ClientsCasesPreviewParagraph>
            <ClientsCasesPreviewAuthor>
              {item.client?.photo && (
                <ClientsCasesPreviewPhoto
                  data-blink-src={item.client.photo}
                  data-blink-quality="smart_retina"
                  alt={item.client?.name}
                />
              )}
              <ClientsCasesPreviewAuthorInfo>
                <strong>{item.client?.name}</strong>
                <br />
                {item.client?.position}
              </ClientsCasesPreviewAuthorInfo>
            </ClientsCasesPreviewAuthor>
          </ClientsCasesPreview>
        ))}
      </ClientsCasesPreviews>
    </ClientsCasesWrapper>
  </ClientsCasesContainer>
);
