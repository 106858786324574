import React from 'react';
import {
  CompanyPageContainer,
  CompanyPageH2,
  CompanyPageDescription,
  CompanyPageContentBlock,
} from './styles/styled-components';
import { CompanyClientsCases } from './styles/Common.styles';

export const Audience = () => (
  <>
    <CompanyPageContainer>
      <CompanyPageContentBlock>
        <CompanyPageH2>Audience</CompanyPageH2>
        <CompanyPageDescription>
          We build a&nbsp;developer-oriented platform with low-level access to the features through
          the&nbsp;API.
        </CompanyPageDescription>
      </CompanyPageContentBlock>
    </CompanyPageContainer>
    <CompanyClientsCases heading="" ppc />
  </>
);
