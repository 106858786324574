import React from 'react';
import { InvestorsLogoImg, InvestorsLogoGrid } from './styles/Investors.styles';
import { CompanyPageContainer, CompanyPageH2 } from './styles/styled-components';

export const Investors = () => (
  <CompanyPageContainer>
    <CompanyPageH2>Our investors</CompanyPageH2>
    <InvestorsLogoGrid>
      <a href="https://www.rainfall.com/" target="_blank" rel="noopener noreferrer">
        <InvestorsLogoImg
          src="https://ucarecdn.com/dc6fb86d-4c59-40f7-a23a-bd4503455357/rainfall.svg"
          alt="Rainfall has concentrated on flexible initial-stage investments since 2011"
        />
      </a>
      <a href="https://runacap.com/" target="_blank" rel="noopener noreferrer">
        <InvestorsLogoImg
          src="https://ucarecdn.com/1e201ace-2fb9-4f38-bc41-63b6a9b13e3e/runa.svg"
          alt="Runa Capital, founded by serial entrepreneurs, invests in early-stage software startups around the world"
        />
      </a>
      <a href="https://www.vendep.com/" target="_blank" rel="noopener noreferrer">
        <InvestorsLogoImg
          src="https://ucarecdn.com/2c9188c4-44ba-45ac-9798-b7c33595c26b/vendep.svg"
          alt="Vendep is an expert in B2B SaaS and B2B marketplaces in Nordic and Baltic regions"
        />
      </a>
      <a href="https://lvl1group.com" target="_blank" rel="noopener noreferrer">
        <InvestorsLogoImg
          src="https://ucarecdn.com/ab28dedb-880f-42ea-adb0-9c4ea867da45/lvl1.svg"
          alt="LVL1 Group is a private firm managing diverse investments globally"
        />
      </a>
    </InvestorsLogoGrid>
  </CompanyPageContainer>
);
