import React from 'react';

import {
  About,
  Audience,
  Investors,
  Products,
  ClientsSection,
  History,
  Team,
  Partners,
  Awards,
  Education,
  Press,
} from '../../components/forCompany';
import { SiteLayout } from '../../components/Layout/SiteLayout';

export const frontmatter = {
  title: 'Company',
  description:
    'Uploadcare provides companies with simple, powerful, developer-friendly building blocks to handle file uploading, processing, and delivery. A complete out-of-the-box solution, built for engineers by engineers.',
};

const schema = {
  '@context': 'https://schema.org',
  '@type': 'AboutPage',
  url: 'https://uploadcare.com/company/',
  name: 'About Uploadcare',
  isPartOf: {
    '@type': 'WebSite',
    id: 'https://uploadcare.com/',
  },
  about: {
    '@type': 'Corporation',
    email: 'hello@uploadcare.com',
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'technical support',
      url: 'https://uploadcare.com/docs/',
      areaServed: 'Worldwide',
    },
    id: 'https://uploadcare.com/',
    description:
      'Uploadcare is file management platform and a CDN for user-generated content. It is a robust file API for uploading, managing, processing, rendering, optimizing and delivering users content.',
    name: 'Uploadcare',
    alternateName: ['Uploadcare Inc.', 'Uploadcare.com'],
    legalName: 'Uploadcare Inc.',
    logo: 'https://ucarecdn.com/7e668407-0c26-498c-9ea0-8709a5343569/uploadcare-logo.png',
    url: 'https://uploadcare.com/',
    mainEntityOfPage: 'https://uploadcare.com/company/',
    owns: {
      '@type': 'Product',
      name: 'File Uploader',
      sameAs: 'https://uploadcare.com/products/file-uploader/',
    },
    founders: [
      {
        '@type': 'Person',
        name: 'Igor Debatur',
        image:
          'https://ucarecdn.com/5c1acd8c-35a8-46fa-a3de-fc309a828a1c/-/format/auto/-/quality/smart/-/preview/200x200/',
        sameAs: [
          'https://uploadcare.com/blog/author/igor-debatur/',
          'https://www.linkedin.com/in/debatur/',
          'https://www.crunchbase.com/person/igor-debatur',
          'https://twitter.com/igordebatur?lang=en-GB',
          'https://www.zoominfo.com/p/Igor-Debatur/1957908090',
          'https://www.instagram.com/igordebatur/',
          'https://www.google.com/search?kgmid=/g/11ny1mrcjz',
        ],
      },
      {
        '@type': 'Person',
        name: 'Anatoly Chernyakov',
        sameAs: [
          'https://golden.com/wiki/Anatoly_Chernyakov-39V68M5',
          'https://theorg.com/org/uploadcare/org-chart/anatoly-chernyakov',
          'https://www.crunchbase.com/person/anatoly-chernyakov',
          'https://www.linkedin.com/in/onedayillbeok/',
        ],
      },
      {
        '@type': 'Person',
        name: 'Dmitry Mukhin',
        sameAs: [
          'https://uploadcare.com/blog/author/dmitry-mukhin/',
          'https://twitter.com/mojololol',
          'https://www.linkedin.com/in/dmitrymukhin/',
        ],
      },
      {
        '@type': 'Person',
        name: 'Alex Karpinsky',
        sameAs: [
          'https://uploadcare.com/blog/author/alex-karpinsky/',
          'https://www.linkedin.com/in/alexander-karpinsky-3678381b3/',
          'https://www.crunchbase.com/person/alex-karpinsky',
          'https://medium.com/@homm86',
        ],
      },
    ],
    foundingDate: '2011',
    audience: 'developers',
    sameAs: [
      'https://ru.wikipedia.org/wiki/Uploadcare',
      'https://twitter.com/uploadcare',
      'https://stackoverflow.com/questions/tagged/uploadcare',
      'https://github.com/uploadcare',
      'https://stackshare.io/uploadcare',
      'https://www.facebook.com/Uploadcare/',
      'https://www.linkedin.com/company/uploadcare/',
      'https://www.youtube.com/channel/UCombxM4LtHrLwCaN1npzpPg',
      'https://www.crunchbase.com/organization/uploadcare-com',
      'https://career.habr.com/companies/uploadcare',
      'https://www.instagram.com/uploadcare/',
      'https://runacap.com/companies/uploadcare/',
      'https://www.g2.com/products/file-uploader/reviews',
      'https://www.featuredcustomers.com/vendor/uploadcare',
      'https://integrately.com/integrations/uploadcare',
      'https://pitchbook.com/profiles/company/104210-56#overview',
      'https://www.wikidata.org/wiki/Q113415403',
      'https://www.g2.com/products/file-uploader/reviews',
      'https://en.wikipedia.org/wiki/User:Hippyju/sandbox',
      'https://aws.amazon.com/marketplace/seller-profile?id=b529a8ca-4925-4640-bb63-8fb240de1ec3',
      'https://www.saasworthy.com/product/uploadcare',
      'https://dev.to/uploadcare',
      'https://wikitia.com/wiki/Uploadcare',
      'https://uploadcare.medium.com/',
    ],
    subjectOf: [
      'https://finance.yahoo.com/news/uploadcare-revolutionizes-content-moderation-unsafe-124600822.html',
      'https://www.youtube.com/watch?v=u77Y_MiB2X0',
      'https://pitchbook.com/profiles/company/104210-56#overview',
    ],
  },
};

const Company = ({ location }) => (
  <SiteLayout pathName={location.pathname} meta={{ ...frontmatter, schema }}>
    <About />
    <Audience />
    <Products />
    <ClientsSection />
    <History />
    <Investors />
    <Team />
    <Partners />
    <Awards />
    <Education />
    <Press />
  </SiteLayout>
);

export default Company;
