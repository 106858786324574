import styled, { css } from 'styled-components';
import { Button } from '@uc-common/button';
import { Container as Wrapper } from '@uc-common/container';
import { BlImage } from '@uc-common/bl-image';

import { BigHeading } from '../../Typography';
import { Link } from '../../Link';

export const ClientsCasesContainer = styled.article(
  ({ theme: { tablet, laptop, desktop } }) => css`
    margin-top: 100px;
    background-color: #f6f7f8;
    padding-top: 80px;

    ${tablet()} {
      margin-top: 120px;
    }

    ${laptop()} {
      margin-top: 140px;
      padding-top: 120px;
    }

    ${desktop()} {
      padding-top: 140px;
    }
  `
);

export const ClientsCasesWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ClientsCasesHeading = styled(BigHeading)`
  text-align: center;
`;

export const ClientsCasesButton = styled(Button)(
  ({ theme: { laptop } }) => css`
    margin: 20px auto 0;

    ${laptop()} {
      margin-top: 30px;
    }
  `
);

export const ClientsCasesPreviews = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 10px;
    margin-top: 50px;

    ${tablet()} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${laptop()} {
      margin-top: 70px;
    }
  `
);

export const ClientsCasesPreview = styled(Link)(
  ({ theme: { tablet, laptop } }) => css`
    background: #ffffff;
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.16),
      0 4px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 25px 20px;
    width: 100%;
    height: max-content;
    text-decoration: none;

    ${tablet()} {
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-10px);
      }
    }

    ${laptop()} {
      padding: 40px 35px;
    }
  `
);

export const ClientsCasesPreviewLogo = styled(BlImage)(
  ({ theme: { laptop }, $width }) => css`
    margin-bottom: 20px;
    max-height: 32px;
    width: ${$width ?? 'auto'};

    ${laptop()} {
      margin-bottom: 22px;
    }
  `
);

export const ClientsCasesPreviewParagraph = styled.p(
  ({ theme: { laptop } }) => css`
    font-size: 15px;
    line-height: 1.5;
    color: #000000;

    ${laptop()} {
      font-size: 16px;
    }
  `
);

export const ClientsCasesPreviewAuthor = styled.div(
  ({ theme: { laptop } }) => css`
    margin-top: 20px;
    display: flex;
    align-items: center;

    ${laptop()} {
      margin-top: 25px;
    }
  `
);

export const ClientsCasesPreviewPhoto = styled(BlImage)`
  width: 47px;
  height: 47px;
  border-radius: 50px;
  object-fit: cover;
  margin-right: 18px;
`;

export const ClientsCasesPreviewAuthorInfo = styled.div`
  font-size: 14px;
  line-height: 1.35;
  color: #596570;
`;
