import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '~/src/components/Link';
import Image from '@uc-common/image';
import { CompanyPageContainer, CompanyPageH2 } from './styles/styled-components';

export const AwardsGrid = styled.div(
  ({ theme: { laptop } }) => css`
    display: grid;
    grid-gap: 25px;
    margin-top: 35px;

    ${laptop()} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
);

export const AwardsItem = styled(Link)(
  ({ theme: { laptop } }) => css`
    --block-padding: 20px;
    padding: var(--block-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #596570;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    background-color: transparent;
    border-radius: 12px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    min-height: 150px;

    &:hover {
      background-color: #f6f7f8;
    }

    ${laptop()} {
      min-height: 200px;
      --block-padding: 40px 30px 25px;
    }
  `
);

export const AwardsItemImage = styled(Image)`
  height: 60px;
  width: auto;
  margin-bottom: 30px;
`;

export const Awards = () => (
  <CompanyPageContainer>
    <CompanyPageH2>Awards</CompanyPageH2>

    <AwardsGrid>
      <AwardsItem to="/blog/uploadcare-among-10-finalists-of-kryptonite-startup-challenge-2020/">
        <AwardsItemImage
          alt="Kryptonite Startup Challenge 2020"
          uuid="9c7e4dd8-ec66-4b3d-b00c-1010f2ddb291"
        />
        Uploadcare among 10 finalists of Kryptonite Startup Challenge 2020
      </AwardsItem>

      <AwardsItem to="/blog/uploadcare-reaches-finals-of-devops-dozen-awards-2020/">
        <AwardsItemImage
          alt="DevOps Dozen Awards 2020"
          uuid="44fa3863-d595-434f-ac64-290325414bfc"
        />
        Uploadcare reaches the finals of the DevOps Dozen Awards 2020
      </AwardsItem>

      <AwardsItem to="/blog/uploadcare-wins-2020-21-cloud-award-for-best-b2b-customer-strategy/">
        <AwardsItemImage
          alt="Cloud Award for best B2B customer strategy"
          uuid="9a62183d-94d8-4624-b4ca-3b08215a5985"
        />
        Uploadcare wins 2020-21 Cloud Award for best B2B customer strategy
      </AwardsItem>
    </AwardsGrid>
  </CompanyPageContainer>
);
