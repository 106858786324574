import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import Image from '@uc-common/image';

export const CompanyPageContainer = styled(Container)(
  ({ theme: { laptop, desktop } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    ${laptop()} {
      margin-top: 90px;
    }

    ${desktop()} {
      margin-top: 100px;
    }
  `
);

export const CompanyPageContainerFirstBlock = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CompanyPageImage = styled(Image)`
  max-width: 1100px;
  width: 100%;
`;

export const CompanyPageContentBlock = styled.div`
  z-index: 1;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  max-width: 100%;
`;

export const CompanyPageH1 = styled.h1(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    color: #000000;
    display: block;

    ${tablet()} {
      font-size: 56px;
    }

    ${laptop()} {
      font-size: 60px;
    }

    ${desktop()} {
      font-size: 70px;
      max-width: 860px;
    }
  `
);

export const CompanyPageH2 = styled.h2(
  ({ theme: { tablet, desktop } }) => css`
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.025em;
    color: black;
    font-weight: 700;
    line-height: 1.4;

    ${tablet()} {
      font-size: 34px;
    }

    ${desktop()} {
      font-size: 38px;
    }
  `
);

export const CompanyPageH3 = styled.h3(
  ({ theme: { tablet, desktop } }) => css`
    font-size: 18px;
    text-align: left;
    width: 100%;
    letter-spacing: -0.025em;
    color: black;
    font-weight: 700;
    line-height: 1.4;

    ${tablet()} {
      font-size: 20px;
    }

    ${desktop()} {
      font-size: 25px;
    }
  `
);

export const CompanyPageDescription = styled.p(
  ({ theme: { tablet, desktop } }) => css`
    font-size: 20px;
    letter-spacing: -0.015em;
    text-align: center;
    line-height: 1.4em;
    color: #596570;
    margin-top: 25px;
    max-width: 1100px;

    ${tablet()} {
      font-size: 22px;
    }

    ${desktop()} {
      font-size: 24px;
      margin-top: 30px;
    }

    strong {
      font-weight: 500;
    }

    a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid;
      border-color: transparent;
      font-weight: 500;
      transition: border-color 0.1s ease-in;

      &:hover {
        border-color: #596570;
      }
    }
  `
);

export const CompanyPageText = styled.p(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 15px;
    letter-spacing: -0.015em;
    text-align: left;
    line-height: 1.6em;
    color: #596570;
    width: 100%;

    &:not(:first-child) {
      margin-top: 15px;
    }

    ul {
      margin-left: 10px;
    }

    ${tablet()} {
      font-size: 14px;
    }

    ${laptop()} {
      font-size: 16px;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    ${desktop()} {
      font-size: 18px;
    }

    strong {
      font-weight: 500;
    }
  `
);
