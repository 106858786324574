import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '~/src/components/Link';
import Image from '@uc-common/image';
import { CompanyPageContainer, CompanyPageH2 } from './styles/styled-components';

export const PressGrid = styled.div(
  ({ theme: { laptop } }) => css`
    display: grid;
    grid-gap: 25px;
    margin-top: 35px;

    ${laptop()} {
      grid-template-columns: repeat(4, 1fr);
    }
  `
);

export const PressItem = styled(Link)(
  ({ theme: { laptop } }) => css`
    --block-padding: 20px;
    padding: var(--block-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #596570;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    background-color: transparent;
    border-radius: 12px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    min-height: 150px;

    &:hover {
      background-color: #f6f7f8;
    }

    ${laptop()} {
      --block-padding: 40px 20px 25px;
    }
  `
);

export const PressItemImage = styled(Image)`
  height: 40px;
  width: auto;
  margin-bottom: 30px;
`;

export const PressSectionContainer = styled(CompanyPageContainer)`
  padding-bottom: 80px;
`;

export const Press = () => (
  <PressSectionContainer>
    <CompanyPageH2>Press About Uploadcare</CompanyPageH2>

    <PressGrid>
      <PressItem
        target="_blank"
        href="https://betakit.com/image-optimization-software-startup-blinkloader-acquired-by-uploadcare/"
      >
        <PressItemImage alt="Betakit" uuid="f4e05d60-be6d-4213-9528-3becbb7e9b65" />
        Image optimization software startup Blinkloader acquired by Uploadcare
      </PressItem>

      <PressItem
        target="_blank"
        href="https://www.techmagazines.net/choosing-the-best-file-uploader-for-your-unique-needs/"
      >
        <PressItemImage alt="TechMagazines" uuid="6a155a85-8640-41ef-a0ef-498c597ffa18" />
        Choosing the Best File Uploader for Your Unique Needs
      </PressItem>

      <PressItem
        target="_blank"
        href="https://www.digitaltechupdates.com/file-upload-as-a-service-enabling-digital-transformation/"
      >
        <PressItemImage alt="Digital Tech" uuid="1e06b27a-35da-409b-9e60-63a3589eb0af" />
        File Upload as a Service: Enabling Digital Transformation
      </PressItem>

      <PressItem
        target="_blank"
        href="https://www.scoopearth.com/role-of-user-generated-content-in-marketing/"
      >
        <PressItemImage alt="Scoopearth" uuid="5b974f21-932d-4699-bce8-d7d3d1ac979c" />
        The Role of User-Generated Content in Marketing
      </PressItem>

      <PressItem target="_blank" href="https://www.pehub.com/uploadcare-takes-in-1-7-mln/">
        <PressItemImage alt="PE Hub" uuid="73b19be4-282e-40dd-991c-9effe9474d13" />
        Uploadcare takes in $1.7mln
      </PressItem>

      <PressItem
        target="_blank"
        href="https://laravel-news.com/uploadcare-filesystem-adapter-for-laravel"
      >
        <PressItemImage alt="Laravel" uuid="d0651e2e-e796-4801-bec1-729700628283" />
        Uploadcare Filesystem Adapter for Laravel
      </PressItem>

      <PressItem
        target="_blank"
        href="https://techcrunch.com/2019/11/12/uploadcare-raises-1-7m-for-its-cdn-platform/"
      >
        <PressItemImage alt="Techcrunch" uuid="ef117433-ff8a-4de2-9553-1d357b95da33" />
        Uploadcare raises $1.7M for its CDN platform
      </PressItem>
    </PressGrid>
  </PressSectionContainer>
);
