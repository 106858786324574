import { Container } from '@uc-common/container';
import styled, { css } from 'styled-components';

export const InvestorsContainer = styled(Container)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 60px;
    align-items: center;

    ${tablet()} {
      margin-bottom: 80px;
    }

    ${laptop()} {
      margin-bottom: 90px;
    }

    ${desktop()} {
      margin-top: 140px;
      margin-bottom: 100px;
    }
  `
);

export const InvestorsHeading = styled.h2(
  ({ theme: { tablet, desktop } }) => css`
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.025em;
    color: black;
    font-weight: 700;
    margin-bottom: 10px;

    ${tablet()} {
      font-size: 28px;
    }

    ${desktop()} {
      font-size: 32px;
    }
  `
);

export const InvestorsLogoImg = styled.img(
  ({ theme: { tablet } }) => css`
    height: 36px;
    opacity: 1;
    transition: transform 0.4s ease;
    transform: translateY(0);

    ${tablet()} {
      height: 46px;
    }

    &:hover {
      transform: translateY(-6px);
    }
  `
);

export const InvestorsLogoGrid = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-template-columns: repeat(2, min-content);
    column-gap: 40px;
    row-gap: 30px;
    margin-top: 40px;
    justify-items: center;

    ${tablet()} {
      grid-template-columns: repeat(4, min-content);
      column-gap: 50px;
      row-gap: 0;
    }
  `
);
