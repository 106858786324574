import React from 'react';
import styled, { css } from 'styled-components';
import { Customers } from '~/src/components/Customers/Customers';
import { CustomerCard } from '~/src/mdx/components/StoryCards/components/CustomerCard';
import {
  CompanyPageContainer,
  CompanyPageH2,
  CompanyPageDescription,
} from './styles/styled-components';
import { STORIES } from './data';

const StyledCustomers = styled(Customers)`
  margin-top: 30px;
`;

export const StoriesContainer = styled.div(
  ({ theme: { laptop } }) => css`
    display: grid;
    grid-gap: 25px;

    ${laptop()} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
);

const items = ['stackshare', 'prezly', 'mozilla', 'loreal', 'webflow'];

export const ClientsSection = () => (
  <CompanyPageContainer>
    <CompanyPageH2>Our Clients</CompanyPageH2>
    <CompanyPageDescription>
      The world’s fastest growing businesses choose Uploadcare. We’re proud to see among our clients
      Stackshare, Prezly, Mozilla, L’oreal, Webflow, and do believe in startups that prepare to
      launch the&nbsp;very first&nbsp;MVP.
    </CompanyPageDescription>

    <StyledCustomers columns={items.length} items={items} />

    <StoriesContainer>
      {STORIES.map((story) => (
        <CustomerCard story={story} />
      ))}
    </StoriesContainer>
  </CompanyPageContainer>
);
