import React from 'react';
import { Link } from '~/src/components/Link';
import {
  CompanyPageContainer,
  CompanyPageH2,
  CompanyPageDescription,
} from './styles/styled-components';

export const Education = () => (
  <CompanyPageContainer>
    <CompanyPageH2>Education</CompanyPageH2>
    <CompanyPageDescription>
      Visit our{' '}
      <Link data-analytics="btn_education_learning-center" to="/learning/">
        Learning Center
      </Link>
      . Get insights on all things network, media and UGC. Learn from fellow Uploadcare developers
      and build better products together.
    </CompanyPageDescription>
  </CompanyPageContainer>
);
