export const PRODUCTS_DATA = [
  {
    header: 'Uploading',
    headerIcon: '69885ae5-b5a6-49d4-b0f3-bde9db54f6b8',
    links: [
      {
        title: 'File uploader',
        url: 'https://uploadcare.com/products/file-uploader/',
        dataAnalytics: 'btn_bottom-menu_products_file-uploader',
      },
      {
        title: 'API',
        url: '/api/',
        dataAnalytics: 'btn_bottom-menu_api',
      },
      {
        title: 'JS upload library',
        url: '/products/file-uploader/js/',
        dataAnalytics: 'btn_bottom-menu_products_js-file-upload-library',
      },
    ],
  },
  {
    header: 'Delivery',
    headerIcon: '9d2e0d5d-93f1-4721-90cc-01dffe48a5a9',
    links: [
      {
        title: 'Image CDN',
        url: 'https://uploadcare.com/cdn/image-cdn/',
        dataAnalytics: 'btn_bottom-menu_products_image-cdn',
      },
      {
        title: 'Proxy',
        url: '/docs/delivery/proxy/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_products_proxy',
      },
      {
        title: 'Adaptive delivery',
        url: '/products/adaptive-delivery/',
        dataAnalytics: 'btn_bottom-menu_products_adaptive-delivery',
      },
    ],
  },
  {
    header: 'Processing',
    headerIcon: '753b9194-8240-40e3-acf9-1322b1ae5d3d',
    links: [
      {
        title: 'Image processing',
        url: 'https://uploadcare.com/cdn/image-processing/',
        dataAnalytics: 'btn_bottom-menu_products_image-processing',
      },
      {
        title: 'GIF to video',
        url: '/docs/transformations/gif-to-video/',
        dataAnalytics: 'btn_bottom-menu_products_gif-to-video',
      },
      {
        title: 'Video processing',
        url: '/docs/transformations/video-encoding/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_products_video-processing',
      },
      {
        title: 'Document conversion',
        url: '/docs/transformations/document-conversion/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_products_document-conversion',
      },
    ],
  },
  {
    header: 'Management',
    headerIcon: '69885ae5-b5a6-49d4-b0f3-bde9db54f6b8',
    links: [
      {
        title: 'Recognition',
        url: '/products/intelligence/',
        dataAnalytics: 'btn_bottom-menu_products_recognition',
      },
      {
        title: 'Security',
        url: '/products/security/',
        dataAnalytics: 'btn_bottom-menu_products_security',
      },
    ],
  },
];

export const STORIES = [
  {
    path: '/customers/aryeo/',
    cardOptions: {
      logo: true,
    },
    companyInfo: {
      name: 'Aryeo',
      url: 'https://aryeo.com/',
      urlText: 'aryeo.com',
      logo: 'aryeo',
      industry: 'Real estate services',
      headquarters: 'Boston, USA',
      employees: '',
      tenure: '',
    },
    frontmatter: {
      title: 'How to automate image processing for a real estate app',
      description:
        'A case study on how Aryeo integrated Uploadcare to upload tens of thousands of professional high-resolution images and resize them on the fly.',
      image: '8f9b7e66-75d3-4e0a-816c-ba63d8b33e05',
    },
  },

  {
    path: '/customers/chatra/',
    cardOptions: {
      logo: true,
    },
    companyInfo: {
      name: 'Chatra',
      url: 'https://chatra.io',
      urlText: 'chatra.io',
      logo: 'chatra',
      industry: 'Marketing',
      headquarters: 'Wilmington, DE, USA',
      employees: '',
      tenure: '',
    },
    frontmatter: {
      title: 'How to quickly implement maintenance-free file handling',
      description:
        'Learn how a live chat app reduced its time to market by 2 months and saved $10K on initial investments — A case study by Uploadcare',
      image: 'bce494d4-1054-4cf3-8b03-8138819b4603',
    },
  },

  {
    path: '/customers/framefox/',
    cardOptions: {
      logo: true,
      text: 'Saving hundreds of hours of engineering time in a competitive market',
    },
    companyInfo: {
      name: 'Framefox',
      url: 'https://www.framefox.co.nz/',
      urlText: 'framefox.co.nz',
      logo: 'framefox',
      industry: 'Online Printing',
      headquarters: 'Auckland, New Zealand',
      employees: '',
      tenure: '2 years',
    },
    frontmatter: {
      title: 'Saving hundreds of hours of engineering time in a competitive market',
      description:
        'How an online printing business used Uploadcare to save hundreds of hours of engineering time and establish a unique position in the market.',
      image: '8f9b7e66-75d3-4e0a-816c-ba63d8b33e05',
    },
  },
];
