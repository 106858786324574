import React from 'react';

import { LogoGrid } from '~/src/components/forBuilder/partnership-logo-grid';

import {
  CompanyPageContainer,
  CompanyPageH2,
  CompanyPageDescription,
} from './styles/styled-components';

export const Partners = () => (
  <CompanyPageContainer>
    <CompanyPageH2>Partners</CompanyPageH2>
    <CompanyPageDescription>
      We partner with AWS, WordPress, YCombinator, Akamai, JotForm, and other players. To join us,
      check out the Partnership section.
    </CompanyPageDescription>

    <LogoGrid />
  </CompanyPageContainer>
);
