import React from 'react';
import styled from 'styled-components';

import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';
import Image from '@uc-common/image';
import {
  CompanyPageContainer,
  CompanyPageH2,
  CompanyPageH3,
  CompanyPageText,
  CompanyPageContentBlock,
} from './styles/styled-components';

const TeamSectionContentBlock = styled(CompanyPageContentBlock)`
  margin-top: 30px;
`;

const TeamSectionImage = styled(Image)`
  width: 100%;
`;

const TeamSectionImageWrapper = styled.div`
  margin-top: 30px;
  padding: 70px;
  max-width: 350px;
`;

export const Team = () => (
  <CompanyPageContainer>
    <CompanyPageH2>Our Team</CompanyPageH2>
    <TeamSectionContentBlock>
      <CompanyPageH3>Founders and CEO</CompanyPageH3>
      <CompanyPageText>
        Uploadcare is founded by tech enthusiasts experienced in web development and image
        processing. The team encountered problems with uploading and file management while running
        their agency, and they finally decided to create an all-in-one solution to work on scale.
        With a shared vision for innovation, the founders have led the company on a path of rapid
        growth and success.
      </CompanyPageText>
    </TeamSectionContentBlock>

    <TeamSectionContentBlock>
      <CompanyPageH3>Developers</CompanyPageH3>
      <CompanyPageText>
        The company started with high-level developers, who established the foundational code and
        innovative systems that underpin Uploadcare’s offerings. But now, we’re growing and glad to
        see new faces. We have several developers’ teams that work in different directions,
        including website development, dashboard management, processing, and platform optimization.
        These professionals are united by a passion for technology and their collective expertise
        ensures that Uploadcare delivers cutting-edge solutions that meet the evolving needs of our
        users.
      </CompanyPageText>
    </TeamSectionContentBlock>

    <TeamSectionContentBlock>
      <CompanyPageH3>Support and Sales Team</CompanyPageH3>
      <CompanyPageText>
        Our support and sales teams are integral to Uploadcare’s commitment to customer
        satisfaction. The support team consists of knowledgeable professionals who are always ready
        to assist our users, while the sales team focuses on understanding clients’ needs and
        offering the best possible solutions.
      </CompanyPageText>
    </TeamSectionContentBlock>

    <TeamSectionContentBlock>
      <CompanyPageText>
        We are a team of driven individuals who believe in making a difference through technology.
        Join us on our journey to redefine what’s possible in the world of file management and
        digital solutions.
      </CompanyPageText>
    </TeamSectionContentBlock>

    <TeamSectionImageWrapper>
      <TeamSectionImage src={logos[BuzzLogoIdEnum.UPLOADCARE].src} alt="Logo" />
    </TeamSectionImageWrapper>
  </CompanyPageContainer>
);
